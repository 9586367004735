<template>
  <div class="container-wrp mb-6">
    <div class="header-wrp">
      <div class="header-wrp__logo">
        <router-link to="/">
          <img style="width: 200px" src="@/assets/logo3.jpg" alt="" />
        </router-link>
      </div>
      <div class="header-wrp__navbar">
        <v-list class="navbar-list-xs">
          <v-list-item
            :ripple="false"
            class="navbar-list-xs__item"
            dense
            to="/"
            router
          >
            <v-list-item-content>
              <v-list-item-title>Strona główna </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            class="navbar-list-xs__item"
            dense
            to="/catalog"
            router
          >
            <v-list-item-content>
              <v-list-item-title>Katalog domów</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn
          color="customdark"
          fab
          small
          class="white--text ml-2 ins-btn"
          href="https://www.instagram.com/w.e.l.c.o.m.e.bud?igsh=amFnaDhsZjY2OXpk"
          target="_blank"
        >
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
      </div>
      <div class="header-wrp__actions">
        <v-btn
          @click="showContactDialog"
          color="customdark"
          class="white--text contact-btn-header"
          rounded
        >
          Kontakt
          <v-icon right dark> mdi-comment </v-icon>
        </v-btn>
        <v-btn
          fab
          outlined
          small
          class="black--text ml-2 ins-btn"
          href="https://www.instagram.com/w.e.l.c.o.m.e.bud?igsh=amFnaDhsZjY2OXpk"
          target="_blank"
        >
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalHeader',
  methods: {
    showContactDialog () {
      window.getApp.$emit('SHOW_CONTACT_DIALOG')
    }
  }
}
</script>

<style lang="scss">
.header-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  &__logo {
    flex: 1;
    display: flex;
  }
  &__actions {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    align-content: center;
  }
  &__navbar {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .ins-btn {
      display: none;
    }
    .v-list {
      display: flex;
      a {
        display: inline;
        flex: none;
        padding: 0;
        min-height: 0;
        margin-right: 12px;
      }
    }
    .v-list-item__title {
      font-size: 18px !important;
      height: auto;
      padding: 4px 0;
      font-weight: 600 !important;
    }
    .v-list-item__content {
      padding: 0 !important;
    }
    .v-list-item--active {
      color: #f5a536 !important;
    }
    .v-list-item--link:before {
      background: none;
    }
    .v-list-item--link::after {
      background: none;
    }
  }
}

@media (max-width: 991px) {
  .header-wrp {
    &__actions {
      display: none !important;
    }
    &__navbar {
      justify-content: flex-end;
      .ins-btn {
        display: flex;
      }
      .navbar-list-xs {
        display: none;
      }
    }
  }
}
@media (max-width: 500px) {
  .header-wrp {
    &__logo {
      img {
        width: 140px !important;
      }
    }
    &__navbar {
      justify-content: flex-end;
      display: flex;
      .v-list-item__title {
        font-size: 15px !important;
      }
    }
  }
}
</style>
