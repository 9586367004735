<template>
  <div class="contact-form-wrp">
    <p class="contact-form-wrp__subtitle">
      Skontaktujemy się z Tobą, przeanalizujemy, o jakim domu marzysz, a
      ostateczna decyzja należy do Ciebie.
    </p>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="name"
        :rules="rules"
        label="Imię"
        required
        color="customdark"
        outlined
        rounded
      ></v-text-field>
      <v-text-field
        v-model="phone"
        :rules="rules"
        label="Numer telefonu"
        required
        color="customdark"
        outlined
        rounded
      ></v-text-field>

      <div style="width: 100%" class="d-flex justify-center">
        <v-btn
          style="width: 270px"
          color="customdark"
          class="mt-2 white--text"
          :loading="loading"
          rounded
          large
          @click="onSubmit"
        >
          Wyślij zgłoszenie <v-icon right dark> mdi-send </v-icon>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['callback', 'project'],
  data () {
    return {
      valid: true,
      name: '',
      rules: [(v) => !!v || 'Field is required'],
      phone: '',
      loading: false
    }
  },
  created () {},
  methods: {
    validate () {
      this.$refs.form.validate()
      //    this.$refs.form.resetValidation();
      //  if (this.$refs.form.validate()) {}
    },
    onSubmit () {
      if (this.$refs.form.validate()) {
        console.log(`${this.project ? this.project : 'No project'}`)
        this.loading = true
        const data = {
          name: this.name,
          phone: this.phone
        }
        if (this.$route.params.id) {
          data.Project_URL = window.location.href
        }
        axios
          .post('https://bot.welcomegrouphub.com/push', {
            data: data,
            company: 'welcomebud'
          })
          .then((response) => {
            console.log(response)
            this.$refs.form.resetValidation()

            window.getApp.$emit(
              'SHOW_MESSAGE_DIALOG',
              'Otrzymaliśmy Twoje zgłoszenie i wkrótce skontaktuje się z tobą nasz specjalista . Odpowiemy na wszystkie twoje pytania. Miłego dnia!'
            )
            if (this.callback) this.callback()
            this.name = ''
            this.phone = ''
            this.loading = false
          })
          .catch((error) => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
.contact-form-wrp {
  &__subtitle {
    text-align: center;
    color: #868686;
  }
}
</style>
