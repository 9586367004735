<template>
  <v-dialog v-model="dialog" width="500" content-class="massage-dialog-wrp">
    <v-card>
      <div class="pa-6 d-flex flex-column align-center">
        <img
          class="mb-3"
          style="width: 200px"
          src="@/assets/logo3.jpg"
          alt=""
        />
        <div>
          <Contact :callback="() => (dialog = false)" :project="project" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Contact from '@/components/ContactForm.vue'
export default {
  components: { Contact },
  data () {
    return {
      dialog: false,
      project: null
    }
  },
  watch: {
    dialog (dialog) {
      if (!dialog) this.project = null
    }
  },
  mounted () {
    window.getApp.$on('SHOW_CONTACT_DIALOG', (project) => {
      console.log(project)
      project = project || null
      this.dialog = true
    })
  }
}
</script>
