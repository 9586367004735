export default {
  namespaced: true,
  state: {
    projects: [
      {
        id: 1,
        name: 'project1',
        label: 'Agnieszka',
        price: '380 700',
        area: '91.8',
        features: [
          { label: 'Cena', value: '380 700zł' },
          { label: 'Obszar projektu', value: '91.8m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: ['project1/1.png', 'project1/plan.png', 'project1/2.png'],
        plan: 'project1/plan.png'
      },
      {
        id: 2,
        name: 'project2',
        label: 'Helena',
        price: '337 400',
        area: '83.73',
        features: [
          { label: 'Cena', value: '337 400zł' },
          { label: 'Obszar projektu', value: '83.73m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: ['project2/1.jpg', 'project2/plan.png', 'project2/2.jpg'],
        plan: 'project2/plan.png'
      },
      {
        id: 3,
        name: 'project3',
        label: 'Oliwia',
        price: '676 350',
        area: '167.46',
        features: [
          { label: 'Cena', value: '676 350zł' },
          { label: 'Obszar projektu', value: '167.46m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: ['project3/1.png', 'project3/plan.png', 'project3/2.png'],
        plan: 'project3/plan.png'
      },
      {
        id: 4,
        name: 'project4',
        label: 'Katarzyna',
        price: '105 300', // Два варіанти ціни
        area: '19.5', // Площа проекту
        features: [
          { label: 'Cena', value: '1) 93 150zł, 2) 105 300zł' },
          { label: 'Obszar projektu', value: '19.5m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project4/1.png',
          'project4/plan.png',
          'project4/2.png',
          'project4/3.png',
          'project4/4.png',
          'project4/5.png',
          'project4/6.png',
          'project4/7.png',
          'project4/8.png'
        ],
        plan: 'project4/plan.png'
      },
      {
        id: 5,
        name: 'project5',
        label: 'Ewa',
        price: '113 400', // Одна ціна
        area: '22.44', // Площа проекту
        features: [
          { label: 'Cena', value: '113 400zł' },
          { label: 'Obszar projektu', value: '22.44m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: ['project5/1.png', 'project5/plan.png'],
        plan: 'project5/plan.png'
      },
      {
        id: 6,
        name: 'project6',
        label: 'Anna',
        price: '407 000', // Одна ціна
        area: '97.5', // Площа проекту
        features: [
          { label: 'Cena', value: '407 000zł' },
          { label: 'Obszar projektu', value: '97.5m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project6/1.png',
          'project6/plan.png',
          'project6/2.png',
          'project6/3.png'
        ],
        plan: 'project6/plan.png'
      },
      {
        id: 7,
        name: 'project7',
        label: 'Amelia',
        price: '184 300', // Одна ціна
        area: '38.1', // Площа проекту
        features: [
          { label: 'Cena', value: '184 300zł' },
          { label: 'Obszar projektu', value: '38.1m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project7/1.png',
          'project7/plan.png',
          'project7/2.png',
          'project7/3.png',
          'project7/4.png',
          'project7/5.png',
          'project7/6.png',
          'project7/7.png'
        ],
        plan: 'project7/plan.png'
      },
      {
        id: 8,
        name: 'project8',
        label: 'Marta',
        price: '211 400', // Одна ціна
        area: '39.1', // Площа проекту
        features: [
          { label: 'Cena', value: '211 400zł' },
          { label: 'Obszar projektu', value: '39.1m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project8/1.png',
          'project8/plan.png',
          'project8/2.png',
          'project8/3.png',
          'project8/4.png',
          'project8/5.png',
          'project8/6.png',
          'project8/7.png'
        ],
        plan: 'project8/plan.png'
      },
      {
        id: 9,
        name: 'project9',
        label: 'Jagoda',
        price: '445 500', // Одна ціна
        area: '92', // Площа проекту
        features: [
          { label: 'Cena', value: '445 500zł' },
          { label: 'Obszar projektu', value: '92m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project9/1.png',
          'project9/plan.png',
          'project9/2.png',
          'project9/3.png',
          'project9/4.png',
          'project9/6.png',
          'project9/7.png',
          'project9/8.png',
          'project9/9.png',
          'project9/10.png'
        ],
        plan: 'project9/plan.png'
      },
      {
        id: 10,
        name: 'project10',
        label: 'Magdalena',
        price: '329 700', // Одна ціна
        area: '69.5', // Площа проекту
        features: [
          { label: 'Cena', value: '329 700zł' },
          { label: 'Obszar projektu', value: '69.5m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project10/1.png',
          'project10/plan.png',
          'project10/2.png',
          'project10/3.png'
        ],
        plan: 'project10/plan.png'
      },
      {
        id: 11,
        name: 'project11',
        label: 'Maja',
        price: '204 500', // Одна ціна
        area: '45.9', // Площа проекту
        features: [
          { label: 'Cena', value: '204 500zł' },
          { label: 'Obszar projektu', value: '45.9m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project11/1.png',
          'project11/plan.png',
          'project11/2.png',
          'project11/3.png',
          'project11/4.png',
          'project11/5.png',
          'project11/6.png'
        ],
        plan: 'project11/plan.png'
      },
      {
        id: 12,
        name: 'project12',
        label: 'Wiktoria',
        price: '380 700', // Одна ціна
        area: '91.8', // Площа проекту
        features: [
          { label: 'Cena', value: '380 700zł' },
          { label: 'Obszar projektu', value: '91.8m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project12/1.png',
          'project12/plan.png',
          'project12/2.png',
          'project12/3.png',
          'project12/4.png',
          'project12/5.png',
          'project12/6.png',
          'project12/7.png',
          'project12/8.png',
          'project12/9.png'
        ],
        plan: 'project12/plan.png'
      },
      {
        id: 13,
        name: 'project13',
        label: 'Monika',
        price: '135 900', // Одна ціна
        area: '31.15', // Площа проекту
        features: [
          { label: 'Cena', value: '135 900zł' },
          { label: 'Obszar projektu', value: '31.15m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: ['project13/1.png', 'project13/plan.png'],
        plan: 'project13/plan.png'
      },
      {
        id: 14,
        name: 'project14',
        label: 'Kinga',
        price: '245 000', // Одна ціна
        area: '54', // Площа проекту
        features: [
          { label: 'Cena', value: '245 000zł' },
          { label: 'Obszar projektu', value: '54m²' },
          {
            label: 'Rama nośna dna, ścian i sufitu',
            value: 'drewno sosnowe, suche, strugane, antyseptyczne'
          },
          {
            label: 'Płyta OSB 22 mm',
            value: 'jako podstawa do układania podłóg laminowanych'
          },
          {
            label: 'Płyta OSB 10 mm',
            value: 'do wzmocnienia szkieletu ścian zewnętrznych'
          },
          { label: 'Izolacja podłogi', value: '200mm' },
          { label: 'Izolacja sufitu', value: '200mm' },
          { label: 'Izolacja ścian zewnętrznych', value: '200mm' },
          { label: 'Izolacja ścian wewnętrznych', value: '100mm' },
          { label: 'Bariera dla wiatru / pary wodnej', value: '' },
          { label: 'Ochrona domu przed gryzoniami', value: '' },
          { label: 'Ochrona domu przed owadami', value: '' },
          { label: 'Fasada', value: 'sosna / okładzina metalowa' },
          { label: 'Dach', value: 'o profilu metalowym' },
          { label: 'System odprowadzania wody', value: 'z metalu' },
          { label: 'Podłoga', value: 'Podłoga laminowana odporna na wilgoć' },
          {
            label: 'Wewnętrzna okładzina ścienna',
            value: 'imitacja drewna, bez sęków, klejona najwyższej klasy'
          },
          { label: 'Drzwi wewnętrzne', value: '' },
          { label: 'Podłoga w łazience', value: 'Płytki ceramiczne' },
          {
            label: 'Okładziny ścian w łazience',
            value:
              'Imitacja drewna, bez sęków, klejona premium, płytki ceramiczne w strefie prysznica'
          },
          {
            label: 'Okładzina sufitowa w łazience',
            value: 'Okładzina sosnowa, bez sęków, klejona, najwyższej klasy'
          },
          {
            label: 'Prysznic',
            value:
              'Strefa prysznica osłonięta hartowanym szkłem. Z baterią, konewką i drabinką. Ściany strefy prysznica wyłożone płytkami ceramicznymi'
          },
          { label: 'Toaleta', value: 'Podwieszana toaleta z instalacją' },
          { label: 'Umywalka', value: 'Umywalka 600x440mm z szafką' },
          { label: 'Lustro', value: '600X800mm z oświetleniem' },
          {
            label: 'Prąd elektryczny',
            value: 'Dystrybucja kabli, rozdzielnica z automatyką'
          },
          {
            label: 'Lampy, gniazda, przełączniki',
            value: 'Zewnętrzne i wewnętrzne'
          },
          {
            label: 'Ogrzewanie',
            value: 'Elektryczne ogrzewanie podłogowe z programatorem'
          },
          {
            label: 'Zaopatrzenie w wodę i odprowadzanie ścieków',
            value:
              'Instalacja rurowa, bojler 80-100 litrów, przewód do ogrzewania rury'
          },
          {
            label: 'Wentylacja',
            value:
              'Zestaw wentylacyjny, wymuszona wentylacja, rekuperatory powietrza'
          }
        ],
        photos: [
          'project14/1.png',
          'project14/plan.png',
          'project14/2.png',
          'project14/3.png',
          'project14/4.png',
          'project14/6.png',
          'project14/7.png'
        ],
        plan: 'project14/plan.png'
      }
    ]
  },
  getters: {
    projects: (state) => state.projects
  },
  mutations: {
    set (state, { type, items }) {
      state[type] = items
    }
  },
  actions: {}
}
