<template>
  <v-dialog v-model="dialog" width="500" content-class="massage-dialog-wrp">
    <v-card>
      <div class="pa-6 d-flex flex-column align-center">
        <img
          class="mb-3"
          style="width: 200px"
          src="@/assets/logo3.jpg"
          alt=""
        />
        <div>
          <p style="text-align: center">{{ text }}</p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      text: ''
    }
  },
  mounted () {
    window.getApp.$on('SHOW_MESSAGE_DIALOG', (text) => {
      this.text = text
      this.dialog = true
      setTimeout(() => {
        this.dialog = false
        this.text = ''
      }, 2000)
    })
  }
}
</script>
